import { Card, CbhIcon, DotSeparatedList, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack, type Theme, useTheme } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { getPlacementDetailsPathWithoutPlacementCandidate } from "@src/appV2/redesign/Placements/paths";
import { type InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { differenceInMinutes, format, isBefore, isSameMinute, startOfMinute } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";

export interface HighlightedWorkerInterviewCardProps {
  interviewId: string;
  start: Date;
  end: Date;
  status: InterviewStatus;
  placementId: string;
  workerTypes: string[];
  workplaceName: string;
  currentDate: Date;
}

function getInterviewStatusParameters(props: {
  startDate: Date;
  workerTypes: string[];
  workplaceName: string;
  currentDate: Date;
  workerTimezone: string;
  theme: Theme;
}) {
  const { startDate, workerTypes, workplaceName, currentDate, workerTimezone, theme } = props;

  let titleText = "";
  let pillText = "";
  const pillColor = theme.palette.background.tertiary;

  // Convert current date to worker's timezone
  const currentDateInWorkerTimezone = utcToZonedTime(currentDate, workerTimezone);

  const startsIn = formatDateRangeDurationCompact({
    startDate: startOfMinute(currentDateInWorkerTimezone),
    endDate: startDate,
  });

  const interviewDetails = `${
    workerTypes.length > 0 ? workerTypes.join(", ") : "job"
  } interview at ${format(startDate, "h:mm a")} ${
    isEmpty(workplaceName) ? "" : ` at ${workplaceName}`
  }`.trim();

  const now = startOfMinute(currentDateInWorkerTimezone);
  const isInterviewStarted = isBefore(startDate, now) || isSameMinute(startDate, now);

  if (isInterviewStarted) {
    pillText = "Interview started";
    titleText = `Attend your ${interviewDetails}`;
  } else {
    pillText = `Starts in ${startsIn}`;
    titleText = `Prepare for your ${interviewDetails}`;
  }

  return { titleText, pillText, pillColor };
}

export function HighlightedWorkerInterviewCard(props: HighlightedWorkerInterviewCardProps) {
  const {
    interviewId,
    start: startDate,
    end: endDate,
    status,
    placementId,
    workerTypes,
    workplaceName,
    currentDate,
  } = props;

  const theme = useTheme();
  const history = useHistory();
  const worker = useDefinedWorker();

  const duration = differenceInMinutes(endDate, startDate);

  const { titleText, pillText, pillColor } = getInterviewStatusParameters({
    startDate,
    workerTypes,
    workplaceName,
    currentDate,
    workerTimezone: worker.tmz,
    theme,
  });

  const handleViewDetails = () => {
    logEvent(APP_V2_APP_EVENTS.BOOKED_INTERVIEW_CLICKED, {
      workerId: worker.userId,
      interviewDetails: {
        start: zonedTimeToUtc(startDate, worker.tmz).toISOString(),
        end: zonedTimeToUtc(endDate, worker.tmz).toISOString(),
        status,
        id: interviewId,
      },
      placementDetails: {
        id: placementId,
        workerTypes: workerTypes ?? [],
      },
    });

    history.push(getPlacementDetailsPathWithoutPlacementCandidate(placementId));
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
        background: (theme: Theme) => theme.palette.intent?.success.gradient,
      }}
      variant="tertiary"
    >
      <CardContent onClick={handleViewDetails}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Pill size="medium" label={pillText} backgroundColor={() => pillColor} />
          <CbhIcon type="briefcase-filled" size="medium" />
        </Stack>
        <Stack direction="column" spacing={5} marginTop={12}>
          <Text variant="h5">{titleText}</Text>
          <DotSeparatedList>
            {[
              workerTypes.length > 0 ? (
                <Text key={workerTypes.join(", ")} variant="body2">
                  {workerTypes.join(", ")} interview
                </Text>
              ) : null,
              isEmpty(workplaceName) ? null : (
                <Text key={workplaceName} variant="body2">
                  {workplaceName}
                </Text>
              ),
              <Text key={duration} variant="body2">
                {duration} mins
              </Text>,
            ].filter(Boolean)}
          </DotSeparatedList>
          <Stack direction="row" alignItems="center" spacing={3}>
            <CbhIcon type="coin" />
            <Text variant="body2">Receive $20 to cover interview transportation costs</Text>
          </Stack>
          <Button variant="outlined" onClick={handleViewDetails}>
            View job details
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
