import { Stack, type StackProps } from "@mui/material";

interface BookingCardSummaryWrapperProps extends Omit<StackProps, "spacing" | "direction"> {}

/**
 * Wrapper for the summary section of a shift card.
 * This is in most cases the time slot icon and all of the texts with shift info like time, duration, workplace name
 *
 * Uses row layout with predefined gap.
 */
export function BookingCardSummaryWrapper(props: BookingCardSummaryWrapperProps) {
  return <Stack spacing={5} direction="row" alignItems="flex-start" {...props} />;
}
