import { DotSeparatedList } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { type ComponentProps, type ReactNode } from "react";

export const textDefaultProps: ComponentProps<typeof Text> = {
  semibold: true,
  color: (theme) => theme.palette.text.secondary,
  variant: "body2",
};

interface DotSeparatedTextProps {
  children: ReactNode | ReactNode[];
}

export function DotSeparatedText(props: DotSeparatedTextProps) {
  const { children } = props;

  return (
    <Text {...textDefaultProps}>
      <DotSeparatedList>{children}</DotSeparatedList>
    </Text>
  );
}
