import { Callout } from "@clipboard-health/ui-components";
import { Box, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { SHIFT_DISCOVERY_URGENT_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";

interface UrgentShiftsCalloutProps {
  count: number;
}

export function UrgentShiftsCallout(props: UrgentShiftsCalloutProps) {
  const { count } = props;
  const history = useHistory();
  const { baseNavigationPath } = useShiftModalsDataContext();

  if (count <= 0) {
    return null;
  }

  return (
    <Callout
      aria-label="View Urgent Shifts"
      title={
        <Stack direction="row" alignItems="center" gap={2} component="span">
          <Box component="span">Urgent</Box>
          <Box component="span">{count}</Box>
        </Stack>
      }
      iconType="time-flies"
      sx={(theme) => ({
        backgroundColor: theme.palette.shift?.urgent.background,
        color: theme.palette.shift?.urgent.text,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALLOUT_VIEWED, { type: "urgentShifts", count });
        history.push(`${baseNavigationPath}/${SHIFT_DISCOVERY_URGENT_PATH}`);
      }}
    />
  );
}
