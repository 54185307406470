export enum ShiftType {
  REGULAR = "regular",
  URGENT = "urgent",
  PRIORITY_LEGACY = "priority-legacy",
  PRIORITY = "priority",
  PRIORITY_PLUS = "priority-plus",
}

export enum CurrentShiftStage {
  NOT_STARTED = "NOT_STARTED",
  CLOCKED_IN = "CLOCKED_IN",
  BREAK_STARTED = "BREAK_STARTED",
  BREAK_ENDED = "BREAK_ENDED",
  CLOCKED_OUT = "CLOCKED_OUT",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  VERIFIED = "VERIFIED",
}

export const MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES = 6 * 60;
export const MANDATORY_BREAK_DURATION_IN_MINUTES = 30;
