import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Card } from "@clipboard-health/ui-components";
import { type Theme } from "@mui/material";

import { getGradientByTimeSlot } from "../getGradientByTimeSlot";

interface HighlightedWorkerShiftCardWrapperProps {
  children: React.ReactNode;
  shiftTimeSlot: TimeSlot;
}

export function HighlightedWorkerShiftCardWrapper(props: HighlightedWorkerShiftCardWrapperProps) {
  const { children, shiftTimeSlot } = props;

  return (
    <Card
      sx={{
        flexGrow: 1,
        background: (theme: Theme) => getGradientByTimeSlot({ timeSlot: shiftTimeSlot, theme }),
      }}
      variant="tertiary"
    >
      {children}
    </Card>
  );
}
