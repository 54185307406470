import { isDefined } from "@clipboard-health/util-ts";

import { PayInfoLabel } from "../Pay/PayInfoLabel";
import { getFormattedShiftPayBreakdown } from "./getFormattedShiftPayBreakdown";

interface ShiftPayInfoLabelProps {
  offerPay?: number;
  finalPay?: number;
  originalAmount?: number;
  isLoading?: boolean;
}

export function ShiftPayInfoLabel(props: ShiftPayInfoLabelProps) {
  const { offerPay, finalPay, originalAmount, isLoading } = props;

  if (isLoading) {
    return <PayInfoLabel isLoading totalPay={undefined} hourlyPay={undefined} />;
  }

  const payBreakdown = getFormattedShiftPayBreakdown(offerPay, finalPay, originalAmount);

  if (!isDefined(payBreakdown)) {
    return null;
  }

  return <PayInfoLabel totalPay={payBreakdown.totalPay} hourlyPay={payBreakdown.hourlyPay} />;
}
