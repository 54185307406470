import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type BoxProps } from "@mui/material";

import { getGradientByTimeSlot } from "./getGradientByTimeSlot";
import { type TimeSlot } from "./Open/types";
import { TimeSlotIcon } from "./TimeSlotIcon";

interface TimeSlotIndicatorProps extends BoxProps {
  timeSlot: TimeSlot;
}

/**
 * Renders time slot icon with additional padding and gradient background
 * Pass `background` and `color` in `sx` prop to override the time slot based styling
 */
export function TimeSlotIndicator(props: TimeSlotIndicatorProps) {
  const { timeSlot, sx } = props;

  return (
    <Box
      sx={mergeSxProps(
        (theme) => ({
          display: "inline-flex",
          alignItems: "center",
          padding: 7,
          borderRadius: theme.borderRadius?.small,
          flexShrink: 0,
          background: getGradientByTimeSlot({ timeSlot, theme }),
        }),
        sx
      )}
    >
      <TimeSlotIcon timeSlot={timeSlot} />
    </Box>
  );
}
