import { type Theme } from "@mui/material";

import { TimeSlot } from "./Open/types";

interface GetGradientByTimeSlotProps {
  timeSlot: TimeSlot;
  theme: Theme;
}

export function getGradientByTimeSlot(props: GetGradientByTimeSlotProps): string | undefined {
  const { timeSlot, theme } = props;

  switch (timeSlot) {
    case TimeSlot.AM: {
      return theme.palette.shift?.day.gradient;
    }

    case TimeSlot.PM: {
      return theme.palette.shift?.evening.gradient;
    }

    case TimeSlot.NOC: {
      return theme.palette.shift?.overnight.gradient;
    }

    default: {
      return undefined;
    }
  }
}
