import { TimeSlot } from "./Open/types";

const labelByTimeSlot: Record<TimeSlot, string> = {
  [TimeSlot.AM]: "Day",
  [TimeSlot.PM]: "Evening",
  [TimeSlot.NOC]: "Overnight",
};

export function getTimeSlotLabel(timeSlot: TimeSlot) {
  return labelByTimeSlot[timeSlot];
}
