import {
  type SearchShiftsQuery,
  type SearchShiftsResponse,
  shifts,
} from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";

export type SearchShiftsQueryOptions = UseGetQueryOptions<SearchShiftsResponse>;
export const GET_SEARCH_SHIFTS_PATH = "/shifts";
export const GET_SEARCH_SHIFTS_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}${GET_SEARCH_SHIFTS_PATH}`;

export function useSearchShifts(
  params?: SearchShiftsQuery,
  options: SearchShiftsQueryOptions = {}
) {
  return useGetQuery({
    url: GET_SEARCH_SHIFTS_URL,
    queryParams: params,
    responseSchema: shifts,
    meta: {
      userErrorMessage: "Something went wrong while searching for shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_SEARCH_SHIFTS_FAILURE,
    },
    ...options,
  });
}
