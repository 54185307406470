/* eslint-disable @typescript-eslint/ban-types */
import { isDefined } from "@clipboard-health/util-ts";
import { isShiftPriorityValid } from "@src/appV2/lib/utils/isShiftPriorityValid";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";

import { ShiftType } from "./constants";

interface ResolveShiftTypeProps {
  isUrgent: boolean | null;
  window?: string | null;
  priorityTill?: string | null;
}

export function resolveShiftType({
  isUrgent,
  window,
  priorityTill,
}: ResolveShiftTypeProps): ShiftType {
  if (isUrgent) {
    return ShiftType.URGENT;
  }

  if (window === ShiftWindow.A_TEAM) {
    return ShiftType.PRIORITY_PLUS;
  }

  if (window === ShiftWindow.CLIPBOARD_SCORE_FIRST) {
    return ShiftType.PRIORITY;
  }

  if (!isDefined(window) && isDefined(priorityTill) && isShiftPriorityValid(priorityTill)) {
    return ShiftType.PRIORITY_LEGACY;
  }

  return ShiftType.REGULAR;
}
/* eslint-enable @typescript-eslint/ban-types */
