import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Card } from "@clipboard-health/ui-components";
import { InternalLink } from "@clipboard-health/ui-react";
import { CardActionArea } from "@mui/material";
import { parseISO } from "date-fns";

import { OnCallShiftCardContent } from "../../Open/OnCall/CardContent";

interface OnCallWorkerShiftCardProps {
  href: string;
  shiftTimeSlot: TimeSlot;
  shiftStart: string;
  shiftEnd: string;
  shiftQualificationName: string;
  shiftHourlyPay?: string;

  workplaceTimezone: string;
}

export function OnCallWorkerShiftCard(props: OnCallWorkerShiftCardProps) {
  const {
    workplaceTimezone,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftQualificationName,
    shiftHourlyPay,
    href,
  } = props;

  return (
    <Card outlined variant="quaternary">
      <CardActionArea component={InternalLink} to={href}>
        <OnCallShiftCardContent
          timeSlot={shiftTimeSlot}
          startDate={parseISO(shiftStart ?? "")}
          endDate={parseISO(shiftEnd ?? "")}
          qualificationText={shiftQualificationName}
          hourlyPay={shiftHourlyPay}
          workplaceTimezone={workplaceTimezone}
          deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
      </CardActionArea>
    </Card>
  );
}
