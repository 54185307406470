import { Card } from "@clipboard-health/ui-components";
import { CardActionArea, CardContent, Skeleton, Stack } from "@mui/material";

export function WorkerShiftCardSkeleton() {
  return (
    <Card sx={{ flexGrow: 1 }} role="progressbar" aria-label="Loading shift">
      <CardActionArea>
        <CardContent>
          <Stack direction="row" spacing={4}>
            <Skeleton variant="rounded" width={60} height={60} />
            <Stack direction="column" spacing={2} sx={{ flexGrow: 1 }}>
              <Skeleton variant="text" width="30%" height="1.25rem" />
              <Skeleton variant="text" width="80%" height="1.25rem" />
              <Skeleton variant="text" width="90%" height="1.25rem" />
              <Skeleton variant="text" width="40%" height="1.25rem" />
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
