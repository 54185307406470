import { Card, CbhIcon, Pill } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Skeleton, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { Button } from "@src/appV2/redesign/components/Button";
import {
  SHIFT_DISCOVERY_LIST_PATH,
  SHIFT_DISCOVERY_ON_CALL_PATH,
} from "@src/appV2/redesign/ShiftDiscovery/paths";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { useOnCallOfferedShifts } from "@src/appV2/redesign/WorkerShifts/OnCallOffers/useOnCallOfferedShifts";
import { type Bookings } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useCheckIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useCheckIsOnCallWorkplace";
import { addHours, isAfter, parseISO } from "date-fns";
import pluralize from "pluralize";
import { generatePath } from "react-router-dom";

import { LearnMoreSheet } from "../../BottomSheet/OnCallShift/LearnMoreSheet";
import { ON_CALL_TIME_SLOT_GRADIENT_MAP } from "../../OnCallIndicator";

interface OnCallStatusCardProps {
  bookings: Bookings[];
}

export function OnCallStatusCard(props: OnCallStatusCardProps) {
  const { bookings } = props;
  const checkIsOnCallShift = useCheckIsOnCallWorkplace();
  const onCallShifts = bookings
    .filter(
      (booking) => booking.type === "shift" && checkIsOnCallShift(booking.data.facility.userId)
    )
    .map((shift) => shift.data) as Shift[];
  const startedOnCallShift = onCallShifts.find((shift) =>
    isAfter(new Date(), parseISO(shift?.start ?? ""))
  );
  const hasShiftStartPassed = isDefined(startedOnCallShift);

  const {
    data: onCallOffers,
    isLoading: isLoadingOnCallOffers,
    isSuccess: isSuccessOnCallOffers,
  } = useOnCallOfferedShifts(startedOnCallShift?._id ?? "", {
    enabled: hasShiftStartPassed,
    staleTime: 10_000,
    refetchInterval: 2 * 60_000,
  });

  const learnMoreModalState = useModalState();

  if (!hasShiftStartPassed) {
    return null;
  }

  const workplaceTimezone = startedOnCallShift.facility.tmz;
  const borderGradient =
    ON_CALL_TIME_SLOT_GRADIENT_MAP[shiftPeriodToTimeSlot[startedOnCallShift.name]];
  const availableOffersCount = onCallOffers?.data.length ?? 0;
  // we want to trim on-call shifts so we only show the first three hours at most
  const startDate = parseISO(startedOnCallShift.start ?? "");
  const endDate = parseISO(startedOnCallShift.end ?? "");
  const maxEndDate = addHours(startDate, 3);
  const adjustedEndDate = isAfter(endDate, maxEndDate) ? maxEndDate : endDate;

  return (
    <Box sx={{ marginTop: 4 }}>
      <Card variant="tertiary">
        <Stack
          p={5}
          borderRadius={(theme) => theme.borderRadius?.medium}
          border="6px solid transparent"
          sx={{
            background: `linear-gradient(white, white) padding-box, ${borderGradient} border-box`,
          }}
        >
          <Stack spacing={5}>
            <Stack direction="row" justifyContent="space-between" width="100%" pb={5}>
              <Pill
                size="medium"
                variant="outlined"
                sx={{ width: "fit-content" }}
                label={`On call until ${formatTime(adjustedEndDate, workplaceTimezone)}`}
                backgroundColor={(theme) => theme.palette.background.tertiary}
              />
              <CbhIcon type="old-phone" />
            </Stack>
            <Text variant="h5">You are on call right now</Text>
            {isLoadingOnCallOffers && <Skeleton variant="rounded" width="100%" height="2rem" />}
            {isSuccessOnCallOffers &&
              (availableOffersCount > 0 ? (
                <>
                  <Text variant="body2" pb={3}>
                    We have offers ready for you
                  </Text>
                  <Button
                    href={generatePath(
                      `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}/${SHIFT_DISCOVERY_ON_CALL_PATH}`,
                      {
                        onCallShiftId: startedOnCallShift._id,
                      }
                    )}
                  >
                    View {availableOffersCount} {pluralize("offer", availableOffersCount)}
                  </Button>
                </>
              ) : (
                <>
                  <Text variant="body2" pb={3}>
                    We&apos;re looking for a placement for you and you&apos;ll be notified as soon
                    as we have one.
                  </Text>
                  <Button variant="outlined" onClick={learnMoreModalState.openModal}>
                    Learn more
                  </Button>
                  <LearnMoreSheet
                    modalState={learnMoreModalState}
                    workplaceId={startedOnCallShift.facility.userId}
                  />
                </>
              ))}
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}
