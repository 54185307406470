import { Card } from "@clipboard-health/ui-components";
import { InternalLink } from "@clipboard-health/ui-react";
import { CardActionArea, useTheme } from "@mui/material";
import { parseISO } from "date-fns";

import { IconButton } from "../../components/IconButton";
import { WorkerShiftCardFooter } from "../Card/Footer";
import { BookingCardContentWrapper } from "../CardContentWrapper";
import { ShiftCardSummaryInfo } from "../Open/CardSummaryInfo";
import { type TimeSlot } from "../Open/types";
import { BookingCardSummaryWrapper } from "../SummaryWrapper";
import { TimeSlotIndicator } from "../TimeSlotIndicator";

export interface WorkerShiftCardProps {
  href: string;
  shiftTimeSlot: TimeSlot;
  shiftStart: string;
  shiftEnd: string;
  shiftQualificationName: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftWorkDurationInHours?: number;

  workplaceName: string;
  workplaceTimezone: string;
}

export function WorkerShiftCard(props: WorkerShiftCardProps) {
  const {
    workplaceName,
    workplaceTimezone,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftQualificationName,
    shiftHourlyPay,
    shiftTotalPay,
    shiftWorkDurationInHours,
    href,
  } = props;

  const theme = useTheme();

  return (
    <Card outlined variant="quaternary" sx={{ flexGrow: 1 }}>
      <CardActionArea component={InternalLink} to={href}>
        <BookingCardContentWrapper>
          <BookingCardSummaryWrapper>
            <TimeSlotIndicator timeSlot={shiftTimeSlot} />

            <ShiftCardSummaryInfo
              dateRange={{ startDate: parseISO(shiftStart), endDate: parseISO(shiftEnd) }}
              timezone={workplaceTimezone}
              deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
              durationInHours={shiftWorkDurationInHours}
              workplaceName={workplaceName}
            />
          </BookingCardSummaryWrapper>

          <WorkerShiftCardFooter
            hourlyPay={shiftHourlyPay}
            totalPay={shiftTotalPay}
            qualificationText={shiftQualificationName}
          />

          <IconButton
            aria-label="Go to shift page"
            size="xSmall"
            variant="outlined"
            iconType="chevron-right"
            sx={{ position: "absolute", right: theme.spacing(6), top: theme.spacing(6) }}
          />
        </BookingCardContentWrapper>
      </CardActionArea>
    </Card>
  );
}
